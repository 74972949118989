
//    const env_name = "local"
const env_name = "live"
var backurl = ""
if(env_name === "local"){
    backurl = "http://localhost:2053"
}
else{
    // backurl = "https://api.smart-gpt.org" // mainet

    backurl ="https://backend-opengpt.maticz.in" // testnet
}

export default backurl;